
import Vue from 'vue'

export default {
    name: 'GiftCardApplication',
    layout: 'main-head',
    components: {},
    async asyncData({$api, $cookies, app, store, route, ...context}) {
        const hasLogin = !!$cookies.get('token');
        const query = route.query;
        const from = context.from || {};
        if(from.name == 'Login' && hasLogin) {
            // 订单
            const response = await $api.order.getOrderInfo({
                id: query.id
            }, false)
            const res = response.result || {};
            // 礼品卡兑换-自动
            const applyResponse = await $api.giftCard.setGiftCardExchange({
                giftCardCode: res.giftCardCode,
                exchangeType: 2
            }, false);
            // 操作
            if(applyResponse.code == 'success') {
                Vue.prototype.$Toast(app.$translate('Applied to your account successfully'))
                app.router.push({
                    name: 'GiftCardBalance'
                })
            }
        }
    },
    props: {},
    data() {
        return {
            skeleton: true,
            hasLogin: false,
            userId: '',
            query: {},
            country: {},
            user: {},
            order: {},
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取用户信息
        getUseInfoAjax() {
            this.$api.user.getUseInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {};
                this.user = res
            })
        },
        // 获取订单信息
        getOrderInfoAjax() {
            this.$api.order.getOrderInfo({
                id: this.query.id
            }).then(response => {
                const res = response.result || {};
                this.order = res
                this.skeleton = false
                this.setData()
            })
        },
        setData() {
            let yfnOrderItem = this.order.yfnOrderItem
            yfnOrderItem.forEach(p => {
                this.order.mainPic = p.mainPic
            })
        },
        // 礼品卡兑换接口
        setGiftCardExchangeAjax() {
            this.$api.giftCard.setGiftCardExchange({
                giftCardCode: this.order.giftCardCode,
                exchangeType: 1
            }).then(response => {
                const res = response.result;
                if(response.code == 'success') {
                    this.$Toast(this.$translate('Applied to your account successfully'))
                    this.$router.push({
                        name: 'GiftCardBalance'
                    })
                }
            })
        },
        // 兑换
        handleApply() {
            if(!this.hasLogin) {
                this.jumpLogin()
                return
            }
            this.setGiftCardExchangeAjax()
        },
        // copy
        handleCopy() {
            this.$fnApi.copy.initial(this.order.giftCardCode)
            this.$Toast('Copied')
        },
        jumpLogin() {
            this.$router.push({
                name: 'Login',
            })
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.hasLogin = this.$login()
            this.userId = this.$storage.get('userId') || ''
            this.country = this.$storage.get('country') || ''
            this.hasLogin && this.getUseInfoAjax()
            this.getOrderInfoAjax()
        }
    },
}
