
export default {
    name: 'GiftCardBalance',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            currency: {},
            biz: {
                amount: '',
                visible: false,
                hasCodeTip: false,
                hasRedeem: false,
                use: [],
                list: []
            },
            count: {
                start: 0,
                end: 0,
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            page: {
                pageSize: 1000,
                pageNum: 1
            },
            form: {
                giftCardCode: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getGiftCardAmountAjax()
        this.getGiftCardUseAjax()
    },
    methods: {
        // 获取礼品卡金额
        getGiftCardAmountAjax() {
            this.$api.giftCard.getGiftCardAmount().then(response => {
                const res = response.result || {};
                this.biz.amount = res.displayTotalAmount
            })
        },
        // 获取礼品卡使用列表
        getGiftCardUseAjax() {
            this.$api.giftCard.getGiftCardUse({
                ...this.page
            }).then(response => {
                const res = response.result || {};
                this.biz.use = res.list || []
                this.setData()
                this.skeleton = false
            })
        },
        setData() {
            let arr = []
            this.biz.use.forEach(p => {
                // state -> 1 兑换收入 2 支付支出 3 退款收入 4 过期支出
                p.title = this.$translate(['Redeem', 'Place Order', 'Order Refund', 'Expired'][p.state - 1])
                p.class = [1, 3].includes(p.state) ? 'color-red' : ''
                p.time = `${this.$dayjs(p.takeStamp).format('HH:mm')}, ${this.$dayjs(p.takeStamp).format('MMM D')}`
                p.rsPrice = `${[1, 3].includes(p.state) ? '+' : '-'}${p.displayAmount}`
                p.rsText = this.$translate(['Claim-code', 'Order No', 'Order No', ''][p.state - 1])
                p.desc = `${p.rsText ? `${p.rsText}: ` : ''}${p.state == 1 ? p.giftCardCode : p.orderId || ''}`
                arr.push({
                    ...p
                })
            })
            this.biz.list = arr
            this.biz.visible = !!this.biz.list.length
        },
        // 礼品卡兑换接口
        setGiftCardExchangeAjax() {
            this.$api.giftCard.setGiftCardExchange({
                giftCardCode: this.form.giftCardCode,
                exchangeType: 1
            }).then(response => {
                if(response.code == 'success') {
                    this.form.giftCardCode = ''
                    this.biz.hasRedeem = false
                    this.getGiftCardAmountAjax()
                    this.getGiftCardUseAjax()
                }
            })
        },
        // rule
        handleRule() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '17058886270654duI09qZ'
                }
            })
        },
        // purchase
        handlePurchase() {
            this.$router.push({
                name: 'GiftCard',
            })
        },
        // back
        handleBack() {
            this.$router.push({
                name: 'Mine'
            })
        },
        // 兑换
        inputCode() {
            this.biz.hasCodeTip = !this.form.giftCardCode
        },
        handleRedeem() {
            this.biz.hasRedeem = true
        },
        handleApply() {
            this.biz.hasCodeTip = !this.form.giftCardCode
            if(this.biz.hasCodeTip) return
            this.setGiftCardExchangeAjax()
        },
        closeRedeem() {
            this.form.giftCardCode = ''
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('E-Gift Card Balance'),
                keywords: this.$translate('E-Gift Card Balance'),
                description: this.$translate('E-Gift Card Balance'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency')
        },
    },
}
