
import PolicyPage from '../policy/index.vue'

export default {
    name: 'GiftCard',
    layout: 'main-head',
    components: {
        PolicyPage
    },
    async asyncData({$api, cookies, ...context}) {
        const response = await $api.giftCard.getGiftCardDetail();
        const res = response.result || {};
        return {
            info: res,
        }
    },
    props: {},
    data() {
        return {
            skeleton: true,
            hasLogin: false,
            info: {},
            country: {},
            biz: {
                visible: false,
                previewVisible: false,
                policyVisible: false,
                card: [],
                firstCard: {},
                amount: [],
                firstAmount: {},
                check: false,
                collapse: [1]
            },
            policy: {},
            banner: {
                list: []
            },
            select: {
                cardId: '',
                card: {},
                amountId: '',
                amount: {}
            },
            address: {
                first: '',
                list: []
            },
            swiperCompo: {},
            form: {
                remarks: this.$translate('Hope you enjoy this YFN Jewelry Gift Card!')
            },
            params: {
                placeProduct: {}
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
        this.hasLogin && this.getAddressListAjax()
        this.skeleton = false
    },
    methods: {
        // 获取礼品卡详情
        getGiftCardAjax() {
            this.$api.giftCard.getGiftCard().then(response => {
                const res = response.result || {};
                this.info = res
            })
        },
        setData() {
            const card = this.info.giftCardInfos || [];
            const firstCard = card[0] || {};
            const amount = this.info.giftCardAmt?.amount || [];
            const firstAmount = amount[0] || {};
            this.biz.firstCard = firstCard
            this.biz.amount = amount
            this.biz.firstAmount = firstAmount
            this.biz.card = card.map(p => {
                const firstCardImage = (p.cardImage)[0] || {}
                return {
                    ...p,
                    url: firstCardImage.url
                }
            })
            this.handleCard(firstCard)
            this.handleAmount(firstAmount)
        },
        // 获取地址列表
        getAddressListAjax() {
            this.$api.address.getAddressList({
                filterTag: true,
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response.result || [];
                this.address.list = res
                this.address.first = this.address.list[0]
            })
        },
        // 获取协议数据
        getPolicy(v) {
            this.policy = v
        },
        // 预览
        handleImg(obj = {}) {
            const arr = this.banner.list.map(p => {
                return p.url
            })
            this.$ImagePreview({
                images: arr,
                startPosition: obj.$index
            })
        },
        // sku选择
        handleCard(obj = {}) {
            const firstCard = this.biz.firstCard
            const cardImage = obj.cardImage || firstCard.cardImage || []
            this.banner.list = cardImage
            this.select.cardId = obj.cardId
            this.select.card = {
                ...(obj.cardId ? obj : firstCard),
                url: cardImage[0]?.url
            }
            this.swiperCompo = {watchOverflow: true}
            if(this.banner.list.length > 1) {
                this.swiperCompo = {watchOverflow: false, hasPag: true, loop: true}
            }
        },
        handleAmount(obj = {}) {
            const firstAmount = this.biz.firstAmount;
            this.select.amountId = obj.amountId
            this.select.amount = obj.amountId ? obj : firstAmount
        },
        // 预览
        handlePreview() {
            this.biz.previewVisible = true
        },
        // 校验
        checkEmail(val) {
            const reg = new RegExp("^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$");
            return !!reg.test(val);
        },
        // 跳转
        jumpPolicy() {
            this.biz.policyVisible = true
            // this.$router.push({
            //     name: 'Policy',
            //     query: {
            //         id: '17058886270654duI09qZ'
            //     }
            // })
        },
        // submit-下单前操作
        handleAgree() {
            this.biz.visible = false
            this.biz.check = true
            this.submit()
        },
        setSubmit(v) {
            const ref = this.$refs.cardForm;
            this.biz.btnType = v
            ref.submit()
        },
        submit() {
            if(!this.biz.check && this.biz.btnType != 2) {
                this.biz.visible = true
                return
            }
            if(this.biz.btnType == 1) {
                this.handleSubmit()
            } else {
                this.handlePreview()
            }
        },
        handleSubmit() {
            this.$Dialog.confirm({
                class: '.giftCard-dialog',
                title: `${this.$translate('Confirm the email address')}`,
                message: `${this.$translate('Please confirm the email address')}
                    <span>
                        <span style="font-size:12px;color: #F8981C;"> ${this.form.buyerEmail} </span>
                        ${this.$translate(`is the recipient's correct email address and the gift card claim code will send to it.`)}
                    </span>`,
                cancelButtonText: `${this.$translate('Edit')}`,
                confirmButtonText: this.$translate('Confirm'),
                confirmButtonColor: '#000',
                getContainer: '.giftCard'
            }).then(() => {
                this.setBuy()
            }).catch(() => {
                this.$VueScrollTo.scrollTo(`.giftCard-form-email`, '', {
                    offset: -70
                })
            });
        },
        // submit-下单
        setBuy() {
            this.setParams()
            this.setPlaceOrderStorage()
            this.setAddress()
        },
        setPlaceOrderStorage() { // submit-下单缓存
            this.$storage.set('order/placeProduct', this.params.placeProduct)
            this.$storage.set('order/placeGiftCard', this.params.placeProduct)
            this.$storage.set('order/placeGiftCardParams', {
                ...this.select,
                form: this.form
            })
            this.$storage.set('order/placePage', location.href)
        },
        setAddress() { // submit-下单地址校验
            let orderAddress = this.$storage.get('order/placeAddress');
            if(!orderAddress && this.address.first) {
                orderAddress = this.address.first
                this.$storage.set('order/placeAddress', this.address.first)
            }
            if(!orderAddress) {
                this.$fnApi.debounce(() => {
                    this.$router.push({
                        name: 'Address',
                        query: {
                            key: 'order/placeAddress',
                            from: 'giftCard'
                        }
                    })
                }, 10)
            } else {
                this.$router.push({
                    name: 'OrderConfirm',
                    query: {
                        from: 'giftCard'
                    }
                })
            }
        },
        setParams() {
            this.params.placeProduct = [{
                ...this.select,
                form: this.form
            }]
        },
        // SEO HEAD
        setHead() {
            const giftCardInfos = this.info.giftCardInfos || [];
            const firstCard = giftCardInfos[0] || {};
            const cardImage = firstCard.cardImage || [];
            const firstCardImage = cardImage[0] || {}
            const head = this.$ctxApi.seoHead.initial({
                title: this.info.seoTitle,
                keywords: this.info.seoTitle,
                description: this.info.seoRemark,
                image: firstCardImage.url || 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            // this.form.remarks = this.$translate('Hope you enjoy this YFN Jewelry Gift Card!')
            this.country = this.$storage.get('country') || {}
            this.hasLogin = this.$login()
        }
    },
}
